import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import adminReducers from './adminReducers';
import merchantReducers from './merchantReducers';
import miscReducers from './miscReducers';
import userReducers from './userReducers';

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    admin: adminReducers,
    merchant: merchantReducers,
    misc: miscReducers,
    user: userReducers,
  });

export default reducers;
