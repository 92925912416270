/**Admin */

//merchants
export const ADMIN_INIT_MERCHANTS = 'ADMIN_INIT_MERCHANTS';
export const ADMIN_ADD_MERCHANT = 'ADMIN_ADD_MERCHANT';
export const ADMIN_UPDATE_MERCHANT = 'ADMIN_UPDATE_MERCHANT';
export const ADMIN_DELETE_MERCHANT = 'ADMIN_DELETE_MERCHANT';
export const ADMIN_INIT_MERCHANT_PREFERENCES = 'ADMIN_INIT_MERCHANT_PREFERENCES';
export const ADMIN_DELETE_MERCHANT_PREFERENCES = 'ADMIN_DELETE_MERCHANT_PREFERENCES';

//orders
export const ADMIN_INIT_ORDERS = 'ADMIN_INIT_ORDERS';
export const SET_ORDER_STATE = 'SET_ORDER_STATE';

//analytics
export const ADMIN_INIT_ORDER_ANALTICS = 'ADMIN_INIT_ORDER_ANALTICS';


/**Merchant */
export const MERCHANT_DETAILS = 'MERCHANT_DETAILS';

//wallet
export const MERCHANT_INIT_WALLET = 'MERCHANT_INIT_WALLET';
export const MERCHANT_INIT_PREFERENCES = 'MERCHANT_INIT_PREFERENCES';

//addressBook
export const MERCHANT_INIT_ADDRESS_BOOK = 'MERCHANT_INIT_ADDRESS_BOOK';
export const MERCHANT_ADD_ADDRESS_BOOK = 'MERCHANT_ADD_ADDRESS_BOOK';
export const MERCHANT_UPDATE_ADDRESS_BOOK = 'MERCHANT_UPDATE_ADDRESS_BOOK';
export const MERCHANT_DELETE_ADDRESS_BOOK = 'MERCHANT_DELETE_ADDRESS_BOOK';

//orders
export const MERCHANT_ORDER_QUOTE = 'MERCHANT_ORDER_QUOTE';
export const MERCHANT_PREVIOUS_ORDERS = 'MERCHANT_PREVIOUS_ORDERS';

/**MISC */
export const SET_LOADING = 'SET_LOADING';

/**USER */
export const SET_QUOTE_DETAILS = 'SET_QUOTE_DETAILS';
export const SET_TRACKING_DETAILS = 'SET_TRACKING_DETAILS';
export const USER_PREVIOUS_TRANSACTION = 'USER_PREVIOUS_TRANSACTION';