import * as actionTypes from '../../actionTypes/index';

const initialState = {
  loading: false,
};

export default function miscReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
}
