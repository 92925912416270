import React, { useState } from 'react';
import { Layout, Button, Menu, Dropdown, notification } from 'antd'; 
import './styles.less';
import logo from '../../assets/logo/logo.png';
import { PageHeader, Grid } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import ContactUsModule from '../ContactUsModule';
const { useBreakpoint } = Grid;


function HeaderModule(){
  const [api, contextHolder] = notification.useNotification();
  const [contactDialog, setContactDialog] = useState(false);
  const screens = useBreakpoint();
  const history = useHistory();
  const dispatch = useDispatch();
  const userToken = window.localStorage.getItem('user_auth_token');

  const handleHome = () => {
    history.location.pathname === '/'  
    ?
    document.getElementById('home').scrollIntoView({behavior:"smooth"})
    :
    dispatch(push('/'))
  }

  const handleOrder = () => {
    dispatch(push('/order'))
  }

  const handleContactUs = () => {
    history.location.pathname === '/'  
    ?
    document.getElementById('contact-us').scrollIntoView({behavior:"smooth"})
    :
    setContactDialog(true);
  }

  const handleLogin = () => {
    history.push('/login');
  }

  const handleAccount = () => {
    history.push('/profile')
  }

  const handleLogout = () => {
    window.localStorage.clear();
    history.push('/');
  }


  const handleMenuClick = (e) => {
    switch (e.key) {
      case "1":
        handleHome();
        break;
      case "2":
        handleOrder();
        break;
      case "3":
        handleContactUs();
        break;
      case "4":
        handleLogin();
        break;
      case "5":
        handleAccount();
        break;
      case "6":
        handleLogout();
        break;
      default:
        break;
    }
  }
  
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">Home</Menu.Item>
      {history.location.pathname !== '/order' && <Menu.Item key="2">Order</Menu.Item>}
      {history.location.pathname !== '/profile' &&  (userToken && userToken !== '' ?
        <Menu.Item key="5">View Account</Menu.Item> :
        <Menu.Item key="4">Login</Menu.Item>)}
      {userToken && userToken !== '' && 
        <Menu.Item key="6" >Logout</Menu.Item>}
      <Menu.Item key="3">Contact Us</Menu.Item>
    </Menu>
  );

  const menu_ui = () => {
   if(screens.xs){
     return (
      <Dropdown overlay={menu}>
        <Button type="text">
          <MenuOutlined />
        </Button>
      </Dropdown>
     )
   }else {
     return [
      <Button type="text" key="1" onClick={handleHome}>Home</Button>,
      history.location.pathname !== '/order' && <Button type="text" key="2" onClick={handleOrder}>Order</Button>,
      history.location.pathname !== '/profile' && (userToken && userToken !== '' ?
        <Button type="text" key="5" onClick={handleAccount}>View Account</Button> :
        <Button type="text" key="4" onClick={handleLogin}>Login</Button>),
      userToken && userToken !== '' && 
        <Button type="text" key="6" onClick={handleLogout}>Logout</Button>,
      <Button type="text" key="3" onClick={handleContactUs}>
        Contact Us
      </Button>,
    ]
   }
  }
  return (
    <Layout style={{margin: 'auto', maxWidth: '1400px'}}>
      {contextHolder}
      <PageHeader
        className='header-root'
        title={<img  width={'128px'} height={'40px'} src={logo} alt="bharatdelivery logo" />}
        extra={menu_ui()}
      />
      <ContactUsModule
        open={contactDialog}
        handleClose={() => setContactDialog(false)}
        api={api}
      />
    </Layout>
  )
}

export default HeaderModule;