import * as actionTypes from '../../actionTypes/index';

const initialState = {
  merchantDetails: {},
  wallet: {},
  preferences: [],
  addressBook: [],
  quote: {},
  prevOrders: {},
};

export default function merchantReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.MERCHANT_DETAILS:
      return {
        ...state,
        merchantDetails: action.payload,
      };
    case actionTypes.MERCHANT_INIT_WALLET:
      return {
        ...state,
        wallet: action.payload,
      };
    case actionTypes.MERCHANT_INIT_PREFERENCES:
      return {
        ...state,
        preferences: [action.payload],
      };
    case actionTypes.MERCHANT_INIT_ADDRESS_BOOK:
      return {
        ...state,
        addressBook: action.payload,
      };
    case actionTypes.MERCHANT_ADD_ADDRESS_BOOK:
      return {
        ...state,
        addressBook: [...state.addressBook, action.payload]
      }
    case actionTypes.MERCHANT_UPDATE_ADDRESS_BOOK:
      let updateAddress = [...state.addressBook];
      let updateIndex = updateAddress.findIndex((a) => a._id === action.payload._id);
      if(updateIndex > -1) {
        updateAddress[updateIndex] = action.payload;
      }
      return {
        ...state,
        addressBook: updateAddress
      }
    case actionTypes.MERCHANT_DELETE_ADDRESS_BOOK:
      let deleteAddress = [...state.addressBook];
      let deleteIndex = deleteAddress.findIndex((a) => a._id === action.payload);
      if(deleteIndex > -1) {
        deleteAddress.splice(deleteIndex, 1);
      }
      return {
        ...state,
        addressBook: deleteAddress
      }
    case actionTypes.MERCHANT_ORDER_QUOTE:
      return {
        ...state,
        quote: action.payload
      }
    case actionTypes.MERCHANT_PREVIOUS_ORDERS:
      return {
        ...state,
        prevOrders: action.payload
      }
    default:
      return state;
  }
}
