import * as actionTypes from '../../actionTypes/index';

const initialState = {
  quote: {},
  trackingDetails: {},
  prevTrans: []
};

export default function userReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_QUOTE_DETAILS:
      return {
        ...state,
        quote: action.payload,
      };
    case actionTypes.SET_TRACKING_DETAILS:
      return {
        ...state,
        trackingDetails: action.payload,
      }
    case actionTypes.USER_PREVIOUS_TRANSACTION:
      return {
        ...state,
        prevTrans: action.payload
      }
    default:
      return state;
  }
}
