import React from 'react';
import Icon from '@ant-design/icons';

function CustomFacebook(){

  const wtfFacebook = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.5801 31.7918C18.4468 31.8135 18.313 31.8335 18.1787 31.8519C18.313 31.8335 18.4468 31.8135 18.5801 31.7918Z" fill="#E5534F"/>
      <path d="M18.9001 31.7371C18.8364 31.7488 18.7727 31.7596 18.709 31.7706C18.7727 31.7596 18.8364 31.7488 18.9001 31.7371Z" fill="#E5534F"/>
      <path d="M17.821 31.8963C17.665 31.9139 17.5081 31.9295 17.3506 31.9427C17.5081 31.9295 17.665 31.9139 17.821 31.8963Z" fill="#E5534F"/>
      <path d="M18.1284 31.8586C18.0535 31.8686 17.978 31.8779 17.9028 31.8867C17.978 31.8779 18.0535 31.8686 18.1284 31.8586Z" fill="#E5534F"/>
      <path d="M19.3354 31.6506C19.2793 31.6626 19.2229 31.6745 19.1665 31.686C19.2229 31.6745 19.2793 31.6626 19.3354 31.6506Z" fill="#E5534F"/>
      <path d="M20.4165 31.382C20.3711 31.395 20.3257 31.4072 20.2803 31.4199C20.3257 31.4072 20.3711 31.395 20.4165 31.382Z" fill="#E5534F"/>
      <path d="M20.0898 31.4715C20.0405 31.4844 19.991 31.4976 19.9414 31.5101C19.991 31.4976 20.0405 31.4847 20.0898 31.4715Z" fill="#E5534F"/>
      <path d="M19.6616 31.5783C19.6094 31.5905 19.5566 31.602 19.5039 31.6137C19.5566 31.602 19.6094 31.5905 19.6616 31.5783Z" fill="#E5534F"/>
      <path d="M17.3457 31.9432C17.2615 31.9502 17.1772 31.9566 17.0928 31.9622C17.1772 31.9566 17.2615 31.9502 17.3457 31.9432Z" fill="#E5534F"/>
      <path d="M32 16C32 7.16479 24.8352 0 16 0C7.16479 0 0 7.16479 0 16C0 24.8352 7.16479 32 16 32C16.094 32 16.1875 31.998 16.2812 31.9963V19.541H12.8438V15.5349H16.2812V12.5869C16.2812 9.16797 18.3684 7.30713 21.4182 7.30713C22.8787 7.30713 24.134 7.41602 24.5 7.4646V11.0378H22.397C20.7378 11.0378 20.4165 11.8264 20.4165 12.9834V15.5349H24.3838L23.8667 19.541H20.4165V31.3818C27.1042 29.4646 32 23.3032 32 16Z" fill="#18A4E0"/>
      <path d="M17.0571 31.9644C16.8906 31.9751 16.7234 31.9836 16.5557 31.9893C16.7234 31.9836 16.8906 31.9751 17.0571 31.9644Z" fill="#E5534F"/>
      <path d="M16.5469 31.9897C16.4585 31.9927 16.3699 31.9949 16.2812 31.9963C16.3699 31.9949 16.4585 31.9927 16.5469 31.9897Z" fill="#E5534F"/>
    </svg>
  );

  return (
    <Icon component={wtfFacebook} />
  )
}

export default CustomFacebook;