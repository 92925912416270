import React from 'react';
import HeaderModule from '../../modules/HeaderModule';

const headerHoc = (WrapComponent) => {
  function HocContent() {
    return (
      <>
        <HeaderModule />
        <WrapComponent />
      </>
    );
  }
  return HocContent;
};

export default headerHoc;
